import React from "react";

import Layout from "../../../components/layout";
import SmallBanner from "../../../components/smallBanner";

const Delivery = () => {
  const lang = "de";

  return (
    <Layout seoTitle="Lieferung" lang={lang} translationPL="/oferta/dostawa/" translationEN="/en/our-services/delivery/">
      <SmallBanner
        title="Lieferung"
        aditionalBreadcrump="Angebot"
        aditionalBreadcrumpLink="/de/angebot/"
      />
      <section className="text-section delivery-info text-section--first">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-5">
              <h2 className="small color--main">
              Individuelle
                <br />
                Produktionspläne
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-5 col-xl-4">
              <img
                className="delivery-info__left-image"
                src={require("../../../assets/img/delivery1.jpg")}
                alt="Dostawa"
              />
            </div>
            <div className="col-12 col-lg-7 col-xl-8">
              <div className="text-section__content">
                <p className="mb-30">
                PalettenWerk garantiert den Kunden schnelle und pünktliche Palettenlieferungen innerhalb von ganz Europa. Bestellungen für Standardpaletten in Polen werden innerhalb von 24 Stunden abgewickelt, Lieferungen in andere Länder erfolgen innerhalb von 3 Werktagen nach Auftragserteilung.
                </p>
                <p className="mb-60">
                Die Organisation der Produktionsprozesse bei PalettenWerk ermöglicht eine vollständige Anpassung der Produktionspläne an die Zyklen und die Häufigkeit der Kundenaufträge. Damit können wir individuelle Lieferpläne realisieren, die zu 100% mit den Kundenbedürfnissen übereinstimmen.
                </p>
                <img
                  src={require("../../../assets/img/timeline2.jpg")}
                  alt="Dostawa"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="offer-delivery offer-delivery--background-white">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-5 col-xl-5">
              <h2 className="small color--main">
              Beladung
                <br />und Ladungssicherheit:
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-7 col-xl-8 offset-lg-5 offset-xl-4">
              <div className="offer-delivery__content">
                <p className="mb-20">
                Für den Transport werden Standard-, Mega- und Sattelanhänger verwendet, in denen jeweils 756, 812, 940 EPAL Europaletten transportiert werden können. Die Art und Weise der Beladung der Paletten hängt von den Präferenzen des Kunden ab.
                </p>
                <p className="mb-60">
                Die Fahrzeuge sind mit einem GPS-GSM-System ausgestattet, das die Überwachung der Fahrzeuge ermöglicht. Jede transportierte Ladung ist im nationalen und internationalen Verkehr durch die Haftpflichtversicherung für Spediteure gedeckt.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="delivery-map">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-5">
              <h2 className="small color--main">
              Palettenlieferungen 
                <br />
                in Europa
              </h2>
            </div>
            <img
              className="delivery-map__image"
              src={require("../../../assets/img/delivery-map.png")}
              alt="Mapa Dostaw"
            />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Delivery;
